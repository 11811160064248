import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { makeStyles } from '@material-ui/core/styles'
import AdsCard from "../components/adsCard"

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardMedia from '@material-ui/core/CardMedia'
import Tooltip from '@material-ui/core/Tooltip'
import Button from '@material-ui/core/Button'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'

import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import WorkIcon from '@material-ui/icons/Work'

import WebIcon from '@material-ui/icons/Web'
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone'
import CreateIcon from '@material-ui/icons/Create'
import InstagramIcon from '@material-ui/icons/Instagram'
import ImportContactsIcon from '@material-ui/icons/ImportContacts'
import PaymentIcon from '@material-ui/icons/Payment'
import NetworkWifiIcon from '@material-ui/icons/NetworkWifi'
import ComputerIcon from '@material-ui/icons/Computer'
import ContactMailIcon from '@material-ui/icons/ContactMail'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import StorefrontIcon from '@material-ui/icons/Storefront';
import AddAlertIcon from '@material-ui/icons/AddAlert';

const WorkPage = ({ data, location }) => {

  const classes = useStyles()

  const contents = [
    {icon: <StorefrontIcon />, key: "shop", color: "green", title: "ECサイト", summary: "弊社ECサイトへ貴社商品の掲載のみならず、ブランディング、コンサルを無料で！", description: "ホームページを持っていない、インターネットで商品を販売したいけどどうしたらいいのかわからないなどのお悩みを一気に解決いたします！"},
    {icon: <WebIcon />, key: "site", color: "#ffc107", title: "WEBサイト・ホームページ作成", summary: "貴施設のホームページをお金を掛けずにやりたい！", description: "これから作りたいと考えている方、今すでにあるけど、固定費を下げたい、自分で作成したものの、運用がなかなかうまくできない方などご相談下さい。"},
    {icon: <PhoneIphoneIcon />, key: "app", color: "#00b0ff", title: "WEBアプリ・スマフォアプリ開発", summary: "貴施設にてアプリを運用したい！", description: "店舗向けのアプリを導入したい、そもそもアプリって何？どういう仕組？些細な疑問にお答えします。"},
    {icon: <CreateIcon />, key: "logo", color: "#f50057", title: "ロゴ、バナー作成", summary: "ロゴやバナーを作りたい", description: "WEBサイトやSNSや広告に使いたいロゴやバナーの作成についてご相談承ります。作成の受注〜作成アドバイスなど"},
    {icon: <InstagramIcon />, key: "sns", color: "linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)", title: "SNS運用相談", summary: "Facebook,Instagram,Twitter等", description: "事業されていて、もしSNSをご活用していないようでしたら、是非ご活用頂くことをおすすめしますが、操作方法やどうしたらいいかわからない方、ご相談承ります"},
    {icon: <AddAlertIcon />, key: "cloudfonding", color: "red", title: "クラウドファンディング コンサル", summary: "実際に弊社もクラウドファンディングで、「達成率1000%超え」の径家あり！", description: "クラウドファンディングに挑戦してみたいけど、どうしたらいいかわからない。掲載しても支援が入るか不安。などのお悩みをブランディング部分からお手伝いします！"},
    {icon: <ImportContactsIcon />, key: "booking", color: "#ff6d00", title: "宿泊・飲食予約サイト", summary: "宿泊予約サイトや、グルメサイト運用相談", description: "貴施設を予約サイトや、グルメサイトに掲載したいけど方法がわからない方などご相談承ります。"},
    {icon: <PaymentIcon />, key: "payment", color: "red", title: "キャッシュレス決済", summary: "スマフォ決済・カード決済導入", description: "導入したいけど、何をどうればいいかわからない、何が何だか分からない方、ご相談下さい。"},
    {icon: <NetworkWifiIcon />, key: "network", color: "#009688", title: "ネットワーク関連", summary: "貴社施設のネットワーク設備", description: "光を導入したけど、宿泊客にも使用させたいWifiを使用させたいけど、どうしたらいいかわからないなどご相談下さい。"},
    {icon: <ComputerIcon />, key: "pc", color: "#aa00ff", title: "パソコン操作関連", summary: "パソコン上の操作について", description: "パソコンを使っているが、これをやりたいけどどうしたらいいかわからない、些細なことでも構いません。ご相談下さい。"},
    {icon: <ThumbUpIcon />, key: "study", color: "#3f51b5", title: "プログラミングレッスン", summary: "ご自身で作成・開発したい方", description: "某プログラミングレッスン企業にて、講師の経験ありですので、興味がある方は、是非ご相談下さい。学生さんも大歓迎です。"},
  ]

  const sites = [
    {
      "key": 1,
      "title": "白浜ゲストハウス うーちゃん家",
      "image": data.guesthouseImage.childImageSharp.fluid.src,
      "url": "https://guesthouse.u-chan-chi.com"
    },
    {
      "key": 2,
      "title": "特選黒毛和牛牝専門 焼肉U",
      "image": data.yakinikuImage.childImageSharp.fluid.src,
      "url": "https://yakiniku-u.ukai-mnap.co.jp/"
    },
    {
      "key": 3,
      "title": "ゲストハウス & カフェ AMUAM うーちゃん家",
      "image": data.cafeImage.childImageSharp.fluid.src,
      "url": "https://amuam-cafe.u-chan-chi.com/"
    },
    {
      "key": 4,
      "title": "楽しくときを過ごす Miy Jammin",
      "image": data.miyJammin.childImageSharp.fluid.src,
      "url": "https://miy-jammin.ukai-mnap.co.jp/"
    },
    {
      "key": 5,
      "title": "This Izu Shimoda",
      "image": data.thisizushimodaImage.childImageSharp.fluid.src,
      "url": "https://thisizushimoda.com/"
    },
    {
      "key": 6,
      "title": "肉とジューシーと私",
      "image": data.meatImage.childImageSharp.fluid.src,
      "url": "https://meat-juice-and-me.u-chan-chi.com/"
    },
    {
      "key": 7,
      "title": "白浜リゾートウォーカー",
      "image": data.workerImage.childImageSharp.fluid.src,
      "url": "https://shirahama-resort-worker.ukai-mnap.co.jp/"
    },
    {
      "key": 8,
      "title": "株式会社UKAI",
      "image": data.corpImage.childImageSharp.fluid.src,
      "url": "https://ukai-mnap.co.jp"
    },
  ]
  return (
    <Layout location={location} title="Work" headerSize="small">
      <SEO
        title="ホームページ作成 ITコンサル 下田 伊豆 | うーちゃん家"
        description="弊社は、宿泊業と飲食業と食品製造業を運営しておりますが、このご時世、どんな事業を行うにも、ITやWEB関連のことが必須となってきいるかと思います。ポスレジ、キャッシュレス、予約サイトの設定、予約管理、SNS集客、自社ホームページなどなど、事業を行う上で必要不可欠です。弊社は、伊豆下田市で事業をしておりますが、ここ下田は素晴らしい観光地でもあるのにも関わらず、集客方法や体制がまだまだ整っておらず、夏以外の集客が課題となっています。私達はこのために、皆様のITにおけるお悩みを解決して行きます！是非ご相談承ります。"
        slug="/work/"
      />
      <div className={classes.root}>
        <div style={{
          backgroundImage: "url(" + data.coverImage.childImageSharp.fluid.src + ")",
        }} className={classes.parallax}>
          <div className={classes.titleCover}>
            <h1 className={classes.title}>
              IT全般・WEB・SNS・NET関連のご相談や開発・制作承ります
              <Typography variant="body1" component="div" className={classes.description}>
                弊社は、宿泊業と飲食業と食品製造業を運営しておりますが、このご時世、どんな事業を行うにも、ITやWEB関連のことが必須となってきいるかと思います。
                <br />
                ECサイト、WEB制作、ポスレジ、キャッシュレス、予約サイトの設定、予約管理、SNS集客、自社ホームページなどなど、事業を行う上で、必要不可欠です。<br />
                弊社は、<b>伊豆下田市</b>で事業をしておりますが、ここ下田は素晴らしい観光地でもあるのにも関わらず、集客方法や体制がまだまだ整っておらず、夏以外の集客が課題となっています。
                <br />
                私達はこのために、皆様のITにおけるお悩みを解決して行こう！
                <br />そして街全体を世界に通づる最高のリゾート地とすることを目的としております。
                どんなお悩みごと、些細なこと、こんなこと聞いていいの？ってことでも全然構いません。是非ご相談下さい。
              </Typography>
            </h1>
          </div>
        </div>

        <Container maxWidth="md">
          <AdsCard slot="9009456012" />
          <div className={classes.topArea}>
            <h1>
              <WorkIcon className={classes.icon}/><span className={classes.iconText}>Contents</span>
            </h1>
          </div>
          <List className={classes.root}>
            {contents.map((content) => {
              return(
                <div key={content.key}>
                  <ListItem key={content.key} alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar style={{
                        background: content.color,
                        filter: `progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 )`,
                      }} >{content.icon}</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      key={content.key}
                      primary={content.title}
                      secondary={
                        <React.Fragment>
                          <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textPrimary"
                          >
                            {content.summary}
                          </Typography>
                          {content.description}
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </div>
              )
            })}
          </List>
          <AdsCard slot="5723982973" />
          <div className={classes.topArea}>
            <h1>
              <CreateIcon className={classes.icon}/><span className={classes.iconText}>自社サイト作成事例</span>
            </h1>
          </div>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={3}
          >
            {sites.map((node) => {
              return (
                <Grid item sm={4} xs={12} key={node.key} className={classes.root}>
                  <Tooltip title={node.title} arrow>
                    <a className={classes.link} rel="noopener noreferrer" href={node.url} target="_blank">
                      <Card>
                        <CardMedia
                          className={classes.media}
                          image={node.image}
                          title={node.title}
                        />
                        <CardActions>
                          <div className={classes.buttonArea}>
                            <Button className={classes.button}>{`WEBサイトへ`} <ArrowRightIcon/></Button>
                          </div>
                        </CardActions>
                      </Card>
                    </a>
                  </Tooltip>
                </Grid>
              )
            })}
          </Grid>
          <AdsCard slot="9009456012" />
          <div className={classes.topArea}>
            <Typography variant="body2" component="div" className={classes.description}>
              ※ その他、大手企業WEBサイト、社内業務ツールや、スマートフォン（iOS/Android）アプリケーションの実装、多数実績あり。
            </Typography>
            <Typography variant="body2" component="div" className={classes.description}>
              使用テクノロジー <br/>
              - OS: Unix / Linux(RedHat) / Ubuntu <br/>
              - 言語: Python, Ruby, Php, Swift, Objective-C, Javascript(EC6, Typescript, Framework(ReactJS, GatsbyJS, RiotJS, VueJS)), GO lang, Java, C(C+, C#), HTML(slim, jade, haml...), cssなど <br/>
              - Cloud Service: Amazon web server, Google Cloud Platform
            </Typography>
            <h1>
              <ContactMailIcon className={classes.icon}/><span className={classes.iconText}>まずはご相談を！</span>
            </h1>
            <Typography variant="body2" component="div" className={classes.description}>
              いまどき（内容にも寄りますが）、ホームページの作成で、何十万もかかりません（まだまだそういう業者さんはいらっしゃいます）。
              逆に何十万も請求する業者さんは、大した技術がない上に、古い方法でしか実装が出来ないため、掛かってしまうのかもしれません。
              さらに、毎月のラーニングコストについても、ほぼサーバーが不要なため、何万円をかけること無く、運用可能です。<br />
              よくわからなくて、諦めてた方、お金がかかるから避けてきた方など<br />
              下記、問い合わせフォーム、Twitter、Instagramなどから、<b>どんな些細なことでも構いません</b>。是非ご相談下さい。
            </Typography>
          </div>
          <AdsCard slot="5723982973" />
        </Container>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    coverImage: file(absolutePath: { regex: "/work.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    guesthouseImage: file(absolutePath: { regex: "/site/guesthouse.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    yakinikuImage: file(absolutePath: { regex: "/site/yakiniku.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    cafeImage: file(absolutePath: { regex: "/site/cafe.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    miyJammin: file(absolutePath: { regex: "/site/miy-jammin.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    meatImage: file(absolutePath: { regex: "/site/meat.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    corpImage: file(absolutePath: { regex: "/site/corp.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    thisizushimodaImage: file(absolutePath: { regex: "/site/thisizushimoda.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    workerImage: file(absolutePath: { regex: "/site/shirahama-worker.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: theme.spacing(5),
  },
  parallax: {
    backgroundColor: `rgba(255,255,255,0.5)`,
    backgroundRepeat: `no-repeat`,
    backgroundSize: `cover`,
    backgroundPosition: `center`,
    height: `800px`,
    [theme.breakpoints.up('sm')]: {
      height: `500px`,
    },
    width: `100%`,
    marginBottom: `15px`,
    borderBottom: '10px solid',
    borderImage: 'black',
    borderImageSlice: '1',
    borderTop: '0',
    borderLeft: '0',
    borderRight: '0',
  },

  titleCover: {
    position: `absolute`,
    [theme.breakpoints.up('sm')]: {
      left: `25%`,
    },
    left: `5%`,
    maxWidth: `984px`,
    width: `95%`,
  },
  title: {
    [theme.breakpoints.up('sm')]: {
      width: `75%`,
    },
    width: `100%`,
    wordBreak: `break-word`,
    color: `white`,
    marginTop: theme.spacing(3),
  },
  description: {
    textAlign: `left`,
    lineHeight: `1.7rem`,
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      width: `100%`,
    },
    width: `95%`,
  },
  topArea: {
    textAlign: `center`,
    paddingTop: theme.spacing(5),
  },
  contents: {
    width: '100%',
    maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
    paddingRight: theme.spacing(1),
  },
  media: {
    height: 0,
    paddingTop: '100%', // 16:9
    backgroundPosition: `top`,
  },
  buttonArea: {
    alignItems: 'center',
    textAlign: 'center',
    width: '100%',
  },
  button: {
    width: `100%`,
  },
  link: {
    boxShadow: "none",
    textDecoration: `none`,
  },
  icon: {
    fontSize: 40,
  },
  iconText: {
    verticalAlign: `top`
  },

}))

export default WorkPage
